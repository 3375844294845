import Swal from 'sweetalert2'

const styleProperties = {
    buttonsStyling: false,
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-dark ms-2'
    }
}

export function createBasicSweetAlert(title)
{
    Swal.fire({
        title,
        icon: 'warning',
        confirmButtonText: "Ok",
        ...styleProperties,
    });
}

export function createYesNoSweetAlert(
    title,
    confirmCallback,
    cancelCallback = () => {}
) {
    Swal.fire({
        title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Nee",
        ...styleProperties,
    }).then(result => {
        if(result.isConfirmed) {
            confirmCallback()
            return;
        }
        cancelCallback()
    });
}
